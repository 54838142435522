<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="$bvModal.show('eventModal')"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label-class="`h5`"
                    label="Select Organisation"
                  >
                    <v-select
                      id="selectStartup"
                      v-model="event.organisation_id"
                      :get-option-label="option => option.users_organizationtable.title"
                      :options="userOrgs"
                      :reduce="org => org.organization_id"
                      placeholder="Choose one from the list"
                    />
                    <hr>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-overlay
                :show="!event.organisation_id"
                rounded="sm"
              >
                <template #overlay>
                  <b-card
                    bg-variant="primary"
                    text-variant="light"
                  >
                    Please Select Organisation
                  </b-card>
                </template>
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex mb-2"
                  >
                    <h4
                      class="mb-0 mr-1"
                      style="padding-top:2px"
                    >
                      <strong>Open To: </strong>
                    </h4>
                    <b-form-checkbox
                      v-b-tooltip="event.public ? 'Disable Public' : 'Make Public'"
                      :checked="event.public"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="updateStatus(!event.public)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="d-flex align-items-center">
                      <b-badge
                        :variant="event.public?'light-primary':'light-danger'"
                        style="text-transform: capitalize;font-size:16px;"
                      >
                        <strong>{{ event.public?"Public":"Inhouse/Restricted" }}</strong>
                      </b-badge>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Event Type"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Event Type"
                        label-for="eventType"
                      >
                        <v-select
                          id="eventType"
                          v-model="event.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Workshop', 'Masterclass','Bootcamp', 'Custom']"
                          label="Event Type"
                          placeholder="Event Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Intended Participation"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Intended Participants"
                        label-for="intendedParticipation"
                      >
                        <v-select
                          id="intendedParticipation"
                          v-model="event.participation"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Startups', 'Students', 'Mentors', 'Partners']"
                          multiple
                          label="Intended Participants"
                          placeholder="Intended Participants"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Event Title"
                      label-for="eventTitle"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Event Title"
                        rules="required"
                      >
                        <b-form-input
                          id="eventTitle"
                          v-model="event.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Title"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Intended Participation"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Delivery Mode"
                        label-for="deliveryMode"
                      >
                        <v-select
                          id="deliveryMode"
                          v-model="event.deliveryMode"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Offline', 'Online', 'Hybrid']"
                          label="Delivery Mode"
                          placeholder="Delivery Mode"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      class="mb-2"
                      label="Select Start date & time"
                      label-for="SelectStartDateAndTime"
                    >
                      <flat-pickr
                        id="SelectStartDateAndTime"
                        v-model="event.beginTime"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                        class="form-control"
                        placeholder="Select start date & time"
                      />
                      <!-- <b-form-datepicker
                        id="SelectStartDateAndTime"
                        today-button
                        reset-button
                        close-button
                        class="form-control"
                        locale="en"
                      ></b-form-datepicker> -->
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      class="mb-2"
                      label="Select End date & time"
                      label-for="SelectEndDateAndTime"
                    >
                      <flat-pickr
                        id="SelectEndDateAndTime"
                        v-model="event.endTime"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate:event.beginTime}"
                        class="form-control"
                        placeholder="Select end date & time"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Event Description"
                      label-for="eventDescription"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Event Description"
                        rules="required"
                      >
                        <b-form-input
                          id="eventDescription"
                          v-model="event.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Event Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Capacity"
                      label-for="eventCapacity"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Capacity"
                        rules="required"
                      >
                        <b-form-input
                          id="eventCapacity"
                          v-model="event.capacity"

                          :state="errors.length > 0 ? false:null"
                          placeholder="Number of participants"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <!-- Styled -->
                    <b-form-group
                      label="Upload Event Poster(Image)"
                      label-for="eventPoster"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File"
                        rules="size:10000"
                      >
                        <b-form-file
                          v-model="event.file"
                          accept="image/jpeg, image/png, image/gif"
                          style="margin-bottom: 0.5rem;"
                          :state="errors.length > 0 ? false:null"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose a file or drop it here..."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-overlay>
            </validation-observer>
          </tab-content>

        </form-wizard>

      </div>
    </b-col>
    <!-- modal starts -->
    <b-modal
      id="eventModal"
      :ok-title="`${(event.lifeCycleStage && event.industry && event.customerFocus && event.offeringsType && event.impactFocus && event.lifeCycleStage.length === 0 && event.industry.length === 0 && event.customerFocus.length === 0 && event.offeringsType.length === 0 && event.impactFocus.length === 0)?'Skip':'Save'}`"
      no-close-on-backdrop
      size="lg"
      title="Additional Details"
      @ok="handlenewsave"
    >
      <h4>You can select multiple options.</h4>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Ideal Startup Lifecycle Stage"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Ideal Startup Lifecycle Stage"
              label-for="lifeCycleStage"
            >
              <v-select
                id="lifeCycleStage"
                v-model="event.lifeCycleStage"
                :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                multiple
                placeholder="Select one or multiple options"
                style="z-index: 1000;"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Participant Industry/Sector"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Participant Industry/Sector"
              label-for="industry"
            >

              <v-select
                id="industry"
                v-model="event.industry"
                :options="industries"
                multiple
                placeholder="Select one or multiple options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <h6><strong>Focus</strong></h6>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Customer Focus"
            label-for="customerFocus"
          >
            <v-select
              id="customerFocus"
              v-model="event.customerFocus"
              :options="['B2C', 'D2C', 'B2B', 'B2G', 'B2B2C', 'B2B2B', 'B2C2B', 'B2C2C', 'B2B2G', 'B2G2B', 'B2G2C', 'B2C2G']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Offerings Type"
            label-for="offeringsType"
          >
            <v-select
              id="offeringsType"
              v-model="event.offeringsType"
              :options="['Product', 'Service', 'Hybrid']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Impact Focus"
            label-for="impactFocus"
          >
            <v-select
              id="impactFocus"
              v-model="event.impactFocus"
              :options="['Profit', 'Social', 'Environmental']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <form-builder
        v-model="applicationForm"
      />
    </b-modal>
    <b-modal
      id="submitModal"
      ok-title="Yes, Submit"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      centered="true"
      size="sm"
      title="Confirm Submit"
      @ok="addEvent()"
      @cancel="$bvModal.show('eventModal')"
    >
      <h6>Adding additional details helps you get the right applicants and participants. Are you sure you do not want to add details?</h6>
    </b-modal>
    <!-- modal ends -->
  </b-row>
</template>

<script>
import {
  // BButton,
  BCard,
  // BFormDatepicker,
  // BCardText,
  BCol,
  BBadge,
  BFormCheckbox,
  // BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  // BTab,
  // BTabs,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    flatPickr,
    BFormFile,
    BBadge,
    // AppTimeline,
    // AppTimelineItem,
    // BTabs,
    // BTab,
    BRow,
    BCol,
    // BFormDatepicker,
    BFormGroup,
    BFormInput,
    vSelect,
    // BButton,
    BFormCheckbox,
    // BFormCheckboxGroup,
    // BCardText,

    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      event: {
        organisation_id: null,
        eventId: null,
        title: null,
        type: null,
        participation: null,
        description: null,
        deliveryMode: null,
        capacity: null,
        beginTime: null,
        endTime: null,
        lifeCycleStage: [],
        industry: [],
        customerFocus: [],
        offeringsType: [],
        impactFocus: [],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inhouseFunds: null,
        file: null,
        public: true,
        // inhouseFundsSize: ['', ''],
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      is_internal: true,
      phasesTab: null,
      required,
      email,
      industries: ['Agnostic',
        'Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      // console.log(arr)
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      this.handleselection(newarr)
      return newarr
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    handleselection(getarr) {
      if (getarr.length === 1) {
        this.event.organisation_id = getarr[0].organization_id
      }
    },
    updateStatus(newstatus) {
      this.event.public = newstatus
    },
    handlenewsave() {
      if (this.event.lifeCycleStage && this.event.industry && this.event.customerFocus && this.event.offeringsType && this.event.impactFocus && this.event.lifeCycleStage.length === 0 && this.event.industry.length === 0 && this.event.customerFocus.length === 0 && this.event.offeringsType.length === 0 && this.event.impactFocus.length === 0) {
        this.$bvModal.show('submitModal')
      } else {
        this.addEvent()
      }
    },
    formatPhases(phases, eventId) {
      phases.forEach(phase => {
        phase.operations_type = 'Phase'
        phase.event_id = eventId
        phase.events_objectivestables = {
          data: phase.objectives,
        }
        delete phase.objectives
        phase.subphases.forEach(subphase => {
          subphase.operations_type = 'SubPhase'
          subphase.event_id = eventId
          const activities = []
          subphase.days.forEach((day, dayIndex) => {
            day.activities.forEach(activity => {
              activity.day = dayIndex + 1
              activity.event_id = eventId
              activity.events_objectivestables = {
                data: activity.objectives,
              }
              delete activity.objectives
              activities.push(activity)
            })
            subphase.events_operationstables = {
              data: activities,
            }
            delete subphase.days
          })
        })
        phase.events_operationstables = {
          data: phase.subphases,
        }
        delete phase.subphases
      })
      return phases
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addEvent()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },
    async addEvent() {
      this.mutationLoading = true
      let fileLink = null
      if (this.event.file) {
        const formData = new FormData()
        formData.append('file', this.event.file)
        await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        }).then(response => response.json())
          .then(data => {
            fileLink = data.file_key
            return data
          })
      }
      // console.log(this.event.beginTime)
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation {
          insert_programs_basicinfo_one(object: {
            status: "draft",
            is_public: "${this.event.public}",
            organization_id_id: "${this.event.organisation_id}",
            target_participants: "${this.event.participation.join(', ')}",
            title: "${this.event.title}",
            is_event: true,
            type: "${this.event.type}",
            description: "${this.event.description}",
            ideal_lifecycle_stage: "${this.event.lifeCycleStage.join(', ')}",
            capacity: "${this.event.capacity}",
            industry: "${this.event.industry.join(', ')}",
            begin_date: "${this.event.beginTime}",
            end_date: "${this.event.endTime}",
            image: "${fileLink}",
            customer_type: "${this.event.customerFocus.join(', ')}",
            offerings_type: "${this.event.offeringsType.join(', ')}",
            focus_type: "${this.event.impactFocus.join(', ')}"
          }) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_basicinfo_one.id ? 'Event created.' : 'Failed to create event',
              icon: insert_programs_basicinfo_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_basicinfo_one.id ? 'info' : 'danger',
            },
          })
          if (insert_programs_basicinfo_one.id) {
            // Program Created, Create Phases
            // this.addPhases(insert_programs_basicinfo_one.id)
            window.location.href = '/incubator-portal/events'
          } else {
            // Error Occurred, stop loading
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    addPhases(eventId) {
      this.$apollo.mutate({
        mutation: gql` mutation($objects: [events_operationstable_insert_input!]!) {
        insert_events_operationstable(objects: $objects) {
          affected_rows
        }
      }`,
        variables: {
          objects: this.formatPhases(this.event.phases, eventId),
        },
        update: () => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: insert_events_operationstable.affected_rows ? 'Phases created successfully' : 'Failed to create phases',
          //     icon: insert_events_operationstable.affected_rows ? 'CheckIcon' : 'XIcon',
          //     variant: insert_events_operationstable.affected_rows ? 'success' : 'danger',
          //   },
          // })
          setTimeout(() => {
            this.mutationLoading = false
            window.location.href = '/incubator-portal/events'
          }, 2000)
        },
      })
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseStructure() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseStructure.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseDetails.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              if (this.event.phases.length > 1) {
                this.$bvToast.toast('Please fill details for all phases!', {
                  title: 'Alert',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: true,
                  solid: true,
                })
              }
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
